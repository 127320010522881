export default function BarSkill(props){
    return(

        <div className="bar_skill_container">
            <h3>{props.args.skill}</h3>
            <div className="bar_skill_bar_container" style={{borderColor: props.args.borderColor}}>
                <div className="bar_skill_bar_status" style={{width: props.args.percent + "%", background: props.args.barColor}}></div>
            </div>

        </div>

    )
}