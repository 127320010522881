import ImgCactulate01 from "../media/modal/cactulate01.png";
import ImgCactulate02 from "../media/modal/cactulate02.png";
import ImgAburra01 from "../media/modal/aburra01.png";
import ImgAburra02 from "../media/modal/aburra02.png";
import ImgAburra03 from "../media/modal/aburra03.png";
import ImgAburra04 from "../media/modal/aburra04.png";

export default function ModalData(props) {

    const contenido = [
        {
            id: "botlancer",
            contenido: modalBotlancer()
        },
        {
            id: "isabella",
            contenido: modalIsabella()
        },
        {
            id: "philipp",
            contenido: modalPhilipp()
        },
        {
            id: "aburra",
            contenido: modalAburra()
        },
        {
            id: "cactulate",
            contenido: modalCactulate()
        }
    ]
    
    return contenido.find(e => e.id === props.id).contenido;
    
}

function modalBotlancer() {

    return(
        <div style={{margin:"0 auto",maxWidth:"900px"}}>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/de158a98853363.5ee5c74cc5ae3.png" alt="01" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/8e816498853363.5ee5c74cc01da.png" alt="02" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/a85cc298853363.5ee5c74cbfaea.png" alt="03" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/84f8dc98853363.5ee5c74cc52ab.png" alt="04" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/3d404298853363.5ee5c74cc15d2.png" alt="05" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/f1f7c298853363.5ee5c74cc4c6d.png" alt="06" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/abd5d498853363.5ee5c74cc34fc.png" alt="07" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/d0dac598853363.5ee5c74cc090e.png" alt="08" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/95337a98853363.5ee5c74cc0fb5.png" alt="09" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/62821598853363.5ee5c74cc1c52.png" alt="10" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/f286d598853363.5ee5c74cc22bb.png" alt="11" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/f253ef98853363.5ee5c74cc288b.png" alt="12" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/def98198853363.5ee5c74cc61f2.png" alt="13" style={{marginBottom: "0.5rem"}}/>
        <a href="https://botlancerapp.netlify.com/" target="_blank" rel="noreferrer" style={{padding: "0.5em 1em",background: "#06acd4", display: "flex", width:"fit-content", margin:"5rem auto",color:"#fff",textDecoration:"none",textTransform:"uppercase",fontWeight:"500",borderRadius:"5rem"}}>Ver Demo</a>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/ba5c1598853363.5ee5c74cc6868.png" alt="14" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/dfcb9398853363.5ee5c74cc46af.png" alt="15" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/d67f4798853363.5ee5c74cc40f1.png" alt="16" style={{marginBottom: "0.5rem"}}/>
        <img src="https://mir-s3-cdn-cf.behance.net/project_modules/fs/3a185098853363.5ee5c74cc2edc.png" alt="17" style={{marginBottom: "0.5rem"}}/>
        </div>
    )
    
}
function modalIsabella() {

    return(
        <>
        <iframe src="https://isabellaaswad.com/" title="Isabella Aswad Ecommerce" style={{width: "100%",height:"100%",border:"none",margin:"0"}}></iframe>
        </>
    )
    
}
function modalPhilipp() {

    return(
        <>
        <iframe src="https://charlesphilipp.ca/" title="Charles Philipp Web" style={{width: "100%",height:"100%",border:"none",margin:"0"}}></iframe>
        </>
    )
    
}
function modalAburra() {
    
    return(
        <div style={{margin:"0 auto",maxWidth:"900px"}}>
        <img src={ImgAburra01} alt="aburra01" style={{marginBottom: "0.5rem"}}/>
        <img src={ImgAburra02} alt="aburra01" style={{marginBottom: "0.5rem"}}/>
        <img src={ImgAburra03} alt="aburra01" style={{marginBottom: "0.5rem"}}/>
        <img src={ImgAburra04} alt="aburra01" style={{marginBottom: "0.5rem"}}/>
        </div>
    )

}
function modalCactulate() {
    
    return(
        <div style={{margin:"0 auto",maxWidth:"900px"}}>
        <img src={ImgCactulate01} alt="aburra01" style={{marginBottom: "0.5rem"}}/>
        <img src={ImgCactulate02} alt="aburra01" style={{marginBottom: "0.5rem"}}/>
        </div>
    )

}