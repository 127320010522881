import './App.css';
import Contenedor from './components/contenedor';

function App() {
  return (
    <Contenedor />
  );
}

export default App;
