export default function TitleCard(props){

    return(

        <div className="title_card_container box_shadow card">
            <div className="title_card_head" >
                <h2 className="title_card_title ">{props.args.title}
                    {props.args.icon && <span className={"icon icon-" + props.args.icon}></span>}
                </h2>
                {props.args.subtitle && <h3 className="title_card_subtitle">{props.args.subtitle}</h3>}
            </div>
            {props.args.description && <p className="title_card_description">{props.args.description}</p>}
            {props.args.content}
        </div>

    )

}