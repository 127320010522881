import Tarjeta from "./Tarjeta";
import React, { useState } from 'react';
import TarjetasData from "../data/tarjetas.json";

export default function Contenedor() {

    const [menuActive, setMenuActive] = useState(false);
    const [tarjetas] = useState( TarjetasData );
    function menuTrigger(index) {
        menuActive ? setMenuActive(false) : setMenuActive(true);
        tarjetas.unshift(tarjetas.splice(index, 1)[0]);
    }
    return (
        <div className="main_container">
            {
                tarjetas.map((element, i) => (
                    <Tarjeta key={i}
                        args={{
                            background: element.background,
                            title: element.title,
                            color: element.color,
                            contenido: element.contenido
                        }} 
                        menu={{
                            active: menuActive, 
                            function: menuTrigger, 
                            position: i, 
                            elements: tarjetas.length
                        }}
                    />
                ))
            }
        </div>
    );

}