export default function Menu(props){

    var arrow = (props.active) ? 'icon-arrow-up' : 'icon-menu';

    return(
        <div className="menu_container" id="menu">
            <span className={arrow} ></span>
        </div>
    );

}