import ModalData from "../data/modal";

export default function Modal(props) {
    
    return(
        <div className="modal_container" >
            <div className="modal_content box_shadow">
                <div className="modal_header">
                    <h3>{props.args.title}</h3>
                    <span className="icon-close" onClick={() => props.args.function(false)}></span>
                </div>
                <div className="modal_desc">
                    <ModalData id={props.args.id}/>
                </div>
            </div>
        </div>
    )
}