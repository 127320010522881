import Menu from "./Menu";
import ContenidoData from "../data/contenido";
import Footer from "./Footer";

export default function Tarjeta(props) {
    
    var heightHeader = 4.2;
    var zIndex = props.menu.elements - props.menu.position - 1;
    var styles = {
        background: props.args.background,
        color: props.args.color,
        zIndex: zIndex,
        transform: 'translateY(' + heightHeader * ( (props.menu.active) ? zIndex : 0 )  + 'rem)'
    };

    return (
        <div className="tarjeta box_shadow card" style={styles}>
            <div className="header_container card" style={{minHeight: heightHeader + 'rem'}} onClick={() => props.menu.function(props.menu.position)}>
                <span className="header_title" >{props.args.title}</span>
                {(props.menu && props.menu.position === 0) && <Menu active={props.menu.active} />}
            </div>
            <div className="contenido_container card">
                {props.args.contenido && <ContenidoData id={props.args.contenido} />}
            </div>
            <Footer />
        </div>
    );
}
