import "../components/contenido/contenido.css"
import ImageCard from "../components/ImageCard";
import Profile from "../media/profile.png";
import Profile02 from "../media/profile02.png";

import ImgBotlancer from "../media/project_botlancer.png";
import ImgPhilipp from "../media/project_philipp.png";
import ImgAswad from "../media/project_aswad.png";
import ImgPopcorn from "../media/project_popcorn.png";
import ImgCactulate from "../media/project_cactulate.png";
import TitleCard from "../components/contenido/TitleCard";
import BarSkill from "../components/contenido/barSkill";

export default function ContenidoData(props) {

    const contenido = [
        {
            id: "home",
            contenido: viewHome()
        },
        {
            id: "projects",
            contenido: viewProjects()
        },
        {
            id: "cv",
            contenido: viewCv()
        },
        {
            id: "contact",
            contenido: viewContact()
        }

    ]

    return contenido.find(e => e.id === props.id).contenido

}
function viewHome() {

    return (
        <div className="home_container card">
            <div className="home_image col-12 col-5-desk card">
                <img src={Profile} alt="Sebastian Cardona" />
            </div>
            <div className="home_desc col-7-desk card">
                <div className="home_desc_container">
                    <h1><span>Hola!, soy</span>Sebastian Cardona</h1>
                    <div className="show_more_container" >
                        <span className="show_more" onClick={() => document.querySelector('#menu').click()}>
                            <span className="link_text">Conoce más</span>
                            <span className="icon-arrow-right"></span>
                        </span>
                    </div>

                </div>

            </div>
        </div >
    )

}
function viewProjects() {

    return (
        <div className="project_container">
            <ImageCard args={{
                title: "Botlancer App",
                subtitle: "UX/UI Design",
                description: "Diseño UX/UI para propuesta de aplicación para CESDE.",
                image: ImgBotlancer,
                modalId: "botlancer",
                showMore: "Ver más"
            }} />
            <ImageCard args={{
                title: "Isabella Aswad",
                subtitle: "E-commerce",
                description: "Desarrollo de ecommerce en Wordpress.",
                image: ImgAswad,
                modalId: "isabella",
                showMore: "Ver ecommerce"
            }} />
            <ImageCard args={{
                title: "Charles Phillip Footwear",
                subtitle: "Landing Page",
                description: "Diseño en Adobe XD, maquetado en HTML, CSS, JS.",
                image: ImgPhilipp,
                modalId: "philipp",
                showMore: "Ver landing page"
            }} />
            <ImageCard args={{
                title: "Popcorn Stand",
                subtitle: "Material Pop / Empaque",
                description: "Esta propuesta reúne el empaque del producto y un exhibidor; cuya inspiración nace de las máquinas crispeteras.",
                image: ImgPopcorn,
                modalId: "aburra",
                showMore: "Ver más"
            }} />
            <ImageCard args={{
                title: "Empaque Cactulate",
                subtitle: "Packaging",
                description: "Cactulate presenta una barra de chocolate en un empaque amigable con el medio ambiente, que se pliega y se forma una maceta. (papel semilla)",
                image: ImgCactulate,
                modalId: "cactulate",
                showMore: "Ver más"
            }} />
        </div>
    )

}
function viewContact() {
    return (

        <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
            <h1 style={{fontSize:"2.5em",fontWeight:"400", textTransform:"",textAlign:"center"}}>¡Gracias por ver mi página web!</h1>
            <div className="show_more_container" style={{justifyContent:"center"}}>
                <a className="show_more" target="_blank" rel="noreferrer" href={"https://wa.me/573147282165/?text=" + encodeURIComponent('Hola Sebas, acabo de ver tu página web!!')}>
                    <span className="link_text">Escríbeme!</span>
                    <span className="icon-arrow-right"></span>
                </a>
            </div>
        </div>
    )
}
function viewCv() {
    return (

        <div className="cv_container" >

            <div className="cv_profile_container card">
                <div className="cv_profile_image col-5-desk card">
                    <img src={Profile02} alt="Sebastian Cardona" />
                </div>
                <div className="cv_profile_head col-12 col-7-desk card">
                    <span className="uno">Sebastian</span>
                    <span className="dos">Cardona</span>
                    <span className="tres">Diseñador</span>
                    <span className="cuatro">Developer</span>
                </div>
            </div>
            <div className="cv_skills_container">
                <div className="cv_skills_container_left">
                    <TitleCard args={{
                        title: "Quién soy",
                        icon: "user",
                        /*subtitle: "Solo texto",*/
                        /*description: "Solo texto",*/
                        content: textCvQuienSoy(),

                    }} />
                    <TitleCard args={{
                        title: "Aptitudes",
                        icon: "magic-wand",
                        /*subtitle: "Solo texto",*/
                        /*description: "Solo texto",*/
                        content: textCvAptitudes(),

                    }} />
                    <TitleCard args={{
                        title: "Habilidades",
                        icon: "energy",
                        /*subtitle: "Solo texto",*/
                        /*description: "Solo texto",*/
                        content: textCvCapacidades(),

                    }} />
                </div>
                <div className="cv_skills_container_right">
                    <TitleCard args={{
                        title: "Conocimientos",
                        icon: "graph",
                        /*subtitle: "Solo texto",*/
                        /*description: "Solo texto",*/
                        content: textCvHabilidades(),

                    }} />
                </div>

            </div>

        </div>

    )
    function textCvQuienSoy() {
        return (
            <>
                <p>Técnico en Diseño Gráfico, Desarrollador Web, tengo 24 años, de la ciudad de Medellín.</p>
                <p>Amante de las tecnologías, polifacético y apasionado
                    por aprender nuevas tecnologías emergentes.</p>
                <p>Cuento con mayor experiencia en diseño digital, marketing digital y desarrollo web
                    (HTML • CSS • JS • PHP • React • Wordpress).</p>
            </>
        )
    }
    function textCvAptitudes() {
        return (
            <>
                <p>Soy una persona analítica, que busca realizar todo proyecto teniendo claro el enfoque de las diferentes personas involucradas.</p>
                <p>Busco que todo proyecto refleje la esencia de la marca, desde la identidad hasta el público objetivo.</p>
                <p>Soy apasionado por la educación, por aprender diariamente con el fin de mejorar y reforzar conocimientos.</p>
                <p>Tengo la capacidad de adaptarme fácilmente a cualquier entorno de trabajo y/o metodología mediante la investigación y la retroalimentación.</p>
            </>
        )
    }
    function textCvCapacidades() {
        return (
            <>
                <p>
                    <span className="check_status" style={{ background: "#fa9f00" }}></span>
                    Desarrollo FrontEnd con React.
                </p>
                <p>
                    <span className="check_status" style={{ background: "#fa9f00" }}></span>
                    Maquetado Web.
                </p>
                <p>
                    <span className="check_status" style={{ background: "#fa9f00" }}></span>
                    Consumo de Api asíncronas con Js.
                </p>
                <p>
                    <span className="check_status" style={{ background: "#fa9f00" }}></span>
                    Soporte Wordpress y Woocommerce (PHP).
                </p>
                <p>
                    <span className="check_status" style={{ background: "#fa9f00" }}></span>
                    Analítica web (Google Analytics).
                </p>
                <p>
                    <span className="check_status" style={{ background: "#fa9f00" }}></span>
                    Diseño UX/UI.
                </p>
                <p>
                    <span className="check_status" style={{ background: "#fa9f00" }}></span>
                    Identidad de Visual.
                </p>
                <p>
                    <span className="check_status" style={{ background: "#fa9f00" }}></span>
                    Motion Graphics.
                </p>
                <p>
                    <span className="check_status" style={{ background: "#fa9f00" }}></span>
                    Diseño Editorial.
                </p>
                <p>
                    <span className="check_status" style={{ background: "#fa9f00" }}></span>
                    Modelado 3D.
                </p>

            </>
        )
    }
    function textCvHabilidades() {
        return (
            <>
                <BarSkill args={{
                    skill: "Adobe XD",
                    percent: "90",
                    borderColor: "#313131",
                    barColor: "#fa9f00",
                }} />
                <BarSkill args={{
                    skill: "Photoshop",
                    percent: "95",
                    borderColor: "#313131",
                    barColor: "#fa9f00",
                }} />
                <BarSkill args={{
                    skill: "Illustrator",
                    percent: "95",
                    borderColor: "#313131",
                    barColor: "#fa9f00",
                }} />
                <BarSkill args={{
                    skill: "After Effect",
                    percent: "80",
                    borderColor: "#313131",
                    barColor: "#fa9f00",
                }} />
                <BarSkill args={{
                    skill: "HTML & CSS",
                    percent: "95",
                    borderColor: "#313131",
                    barColor: "#fa9f00",
                }} />
                <BarSkill args={{
                    skill: "Javascript",
                    percent: "90",
                    borderColor: "#313131",
                    barColor: "#fa9f00",
                }} />
                <BarSkill args={{
                    skill: "PHP",
                    percent: "85",
                    borderColor: "#313131",
                    barColor: "#fa9f00",
                }} />
                <BarSkill args={{
                    skill: "React Js",
                    percent: "70",
                    borderColor: "#313131",
                    barColor: "#fa9f00",
                }} />
                <BarSkill args={{
                    skill: "Wordpress",
                    percent: "90",
                    borderColor: "#313131",
                    barColor: "#fa9f00",
                }} />
            </>
        )
    }

}