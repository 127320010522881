import { useState } from "react";
import Modal from "./Modal";

export default function ImageCard(props) {

    const [modalActive, setModalActive] = useState(false);

    function showModal(e) {
        setModalActive(e)
    }

    return (

        <div className="image_card_container box_shadow card">
            <div className="image_card_content card">
                <img src={props.args.image} alt="" className="image_card_img" />
                <div className="image_card_content_desc">
                    <h2 className="image_card_title">{props.args.title}</h2>
                    <h3 className="image_card_subtitle">{props.args.subtitle}</h3>
                    <p className="">{props.args.description}</p>
                    {
                        props.args.showMore &&
                        <div className="show_more_container" >
                            <span className="show_more" onClick={() => showModal(true)}>
                                <span className="link_text">{props.args.showMore}</span>
                                <span className="icon-arrow-right"></span>
                            </span>
                        </div>
                    }
                </div>
            </div>
            {
                props.args.showMore &&
                modalActive &&
                <Modal args={{
                    title: props.args.title,
                    id: props.args.modalId,
                    function: showModal,
                    active: modalActive
                }} />
            }
        </div>

    )

}